exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-components-blog-card-js": () => import("./../../../src/pages/blog/components/BlogCard.js" /* webpackChunkName: "component---src-pages-blog-components-blog-card-js" */),
  "component---src-pages-blog-components-categories-js": () => import("./../../../src/pages/blog/components/Categories.js" /* webpackChunkName: "component---src-pages-blog-components-categories-js" */),
  "component---src-pages-blog-components-hidden-search-forms-helper-js": () => import("./../../../src/pages/blog/components/HiddenSearchFormsHelper.js" /* webpackChunkName: "component---src-pages-blog-components-hidden-search-forms-helper-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-setup-a-postfix-mailserver-mdx": () => import("./../../../src/pages/blog/setup-a-postfix-mailserver.mdx" /* webpackChunkName: "component---src-pages-blog-setup-a-postfix-mailserver-mdx" */),
  "component---src-pages-blog-ten-minutes-of-unicode-mdx": () => import("./../../../src/pages/blog/ten-minutes-of-unicode.mdx" /* webpackChunkName: "component---src-pages-blog-ten-minutes-of-unicode-mdx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-icon-list-js": () => import("./../../../src/pages/projects/IconList.js" /* webpackChunkName: "component---src-pages-projects-icon-list-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */)
}

